import { useCallback, useEffect, useMemo, useState } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import SelectPeriodsDialog from './components/SelectPeriodsDialog/SelectPeriodsDialog';
import { AlertTitle } from '@material-ui/lab';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { MONTHS } from '../BulkPaymentsDialog/BulkPaymentsDialog';
import { MenuItem } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: (changed?: boolean) => void;
    superUserId?: string;
    season: 'summer' | 'winter' | 'winter_facilities' | 'summer_facilities';
    refreshData: () => void;
    year?: number;
}

const initialData = {
    'paid': '0.00',
    'trainerTaxes': '0.00',
    'esTaxes': '0.00',
    'method': '',
    'invoiceNumber': '',
    'ignoreDeduction135': false
};

const AddPaymentDialog = (props: Props) => {
    const { open, closeDialog, superUserId, season, refreshData: externalRefreshData, year } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState<any>(initialData);
    const [savedData, setSavedData] = useState<any>(initialData);

    const [isSelectPeriodsDialogOpen, setIsSelectPeriodsDialogOpen] = useState(false);

    const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
    const [selectedPeriodDesc, selectPeriodDesc] = useState<GenericObject>({});

    const sortedSelectedPeriods = useMemo(() => {
        const mainArray = savedData?.periods ?? [];

        return selectedPeriods.sort((a: any, b: any) => {
            return mainArray.findIndex((item: any) => item.id === a) - mainArray.findIndex((item: any) => item.id === b);
        })
    }, [savedData, selectedPeriods]);

    const selectPeriod = useCallback((id: string, selected: boolean) => {
        setSelectedPeriods((currPeriods: any) => {
            const filteredPeriods = currPeriods.filter((x: string) => x !== id);

            if (selected) {
                return [...filteredPeriods, id];
            } else {
                return filteredPeriods;
            }
        });
    }, []);

    useEffect(() => {
        const toPay = (savedData?.periods ?? []).filter((p: any) => sortedSelectedPeriods.includes(p.id)).reduce((prev: any, curr: any) => {
            return prev + curr.toPay;
        }, 0.0);

        const globalToPay = Math.max(0, parseFloat(savedData?.paymentStatus?.totalPayouts ?? 0) - parseFloat(savedData?.paymentStatus?.paidPayouts ?? 0));

        setData((d: any) => {
            return {
                ...d,
                paid: Math.min(Math.max(toPay, 0), globalToPay).toFixed(2)
            };
        });
    }, [savedData, sortedSelectedPeriods]);

    const selectedPeriodsDesc = useMemo(() => {
        for (const monthPeriod of (savedData?.monthPeriods ?? [])) {
            if (monthPeriod.fullWeeks.every((w: any) => sortedSelectedPeriods.includes(w)) && sortedSelectedPeriods.every((w: any) => [...monthPeriod.fullWeeks, ...monthPeriod.partialWeeks].includes(w))) {
                return [monthPeriod.name];
            }
        }

        return sortedSelectedPeriods.map((p: any) => {
            return (savedData?.periods ?? []).find((p2: any) => p2.id === p)?.name;
        });
    }, [sortedSelectedPeriods, savedData]);

    useEffect(() => {
        const arePeriodsMonths = selectedPeriodsDesc.every((period: string) => MONTHS.some((month: string) => period.startsWith(month)));

        selectPeriodDesc({ areMonths: arePeriodsMonths, periods: selectedPeriodsDesc });
    }, [selectedPeriodsDesc]);

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/trainer_payments/' + superUserId + '/' + season + '/info').then(res => {
            // setData(res);
            setSavedData(res);
            setIsLoading(false);
        });
    }, [superUserId, season]);

    useEffect(() => {
        if (!superUserId || !open) return;

        refreshData();
    }, [open, superUserId, season, refreshData]);

    useEffect(() => {
        if (!open) return;

        setData((d: any) => {
            return {
                ...d,
                ...initialData
            };
        });

        setSelectedPeriods([]);
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments', 'POST', { ...data, selectedPeriods: sortedSelectedPeriods, selectedPeriodDesc: { ...selectedPeriodDesc, overrideDesc: null }, year }).then(() => {
            snackbar.success('Pagamento registrato correttamente!');
            externalRefreshData();
            closeDialog();
            setData((d: any) => {
                return {
                    ...d,
                    ...initialData
                };
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data, sortedSelectedPeriods, superUserId, season, selectedPeriodDesc, closeDialog, externalRefreshData, year]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    const dialogContent = useMemo(() => {
        const toPay = parseFloat(savedData?.paymentStatus?.toPayPayouts ?? 0);
        const rimanenza5k = Math.max(5000 - parseFloat(savedData?.paidAmountCurrentFiscalYear ?? 0.0), 0);
        const overflow = Math.max(data.paid - rimanenza5k, 0.0);
        const net = Math.min(data.paid, rimanenza5k) + overflow * (data.ignoreDeduction135 ? 1.0 : 0.865);

        const rimanenza15k = Math.max(15000 - parseFloat(savedData?.paidAmountCurrentFiscalYear ?? 0.0), 0);
        const overflow15k = Math.max(data.paid - rimanenza15k, 0.0);

        return (
            <>
                <TableContainer style={{ marginTop: '0', marginBottom: '12px' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Periodo di riferimento</TableCell>
                                <TableCell style={{ padding: '8px 0' }}>
                                    {selectedPeriodsDesc.map((desc: any) => {
                                        return (
                                            <p style={{ margin: '1px 0' }}>{desc}</p>
                                        )
                                    })}
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '8px 0', width: '1px', whiteSpace: 'nowrap' }}>
                                    <Button onClick={() => setIsSelectPeriodsDialogOpen(true)} color={'primary'} variant={'outlined'} style={{ margin: '0 auto', display: 'block' }}>
                                        Seleziona
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: 'flex', marginTop: '4px' }}>
                    <CustomTextField
                        style={{ marginRight: '6px', flex: 2 }}
                        label='Importo'
                        variant='outlined'
                        required
                        value={data.paid}
                        keepMounted={true}
                        onChange={(e) => {
                            setData((d: any) => {
                                return {
                                    ...d,
                                    paid: e.target.value
                                };
                            });
                        }}
                        onBlur={() => {
                            setData((d: any) => {
                                return {
                                    ...d,
                                    paid: Math.min(Math.max(((typeof d.paid === 'string') ? parseFloat(d.paid.replaceAll(',', '.')) : d.paid), 0), toPay).toFixed(2)
                                };
                            });
                        }}
                        disabled={isLoading}
                    />

                    {overflow > 0.001 && !(data?.invoiceNumber ?? '').trim() && (
                        <CustomTextField
                            style={{ marginLeft: '6px', flex: 3 }}
                            label='Ignora decurtazione 13.5%'
                            variant='outlined'
                            value={data.ignoreDeduction135 ? 'yes' : 'no'}
                            keepMounted={true}
                            onChange={(e) => {
                                setData((d: any) => {
                                    return {
                                        ...d,
                                        ignoreDeduction135: e.target.value === 'yes'
                                    };
                                });
                            }}
                            disabled={isLoading}
                            select
                        >
                            <MenuItem value='no' style={{ whiteSpace: 'break-spaces' }}>
                                no
                            </MenuItem>

                            <MenuItem value='yes' style={{ whiteSpace: 'break-spaces' }}>
                                sì
                            </MenuItem>
                        </CustomTextField>
                    )}

                    <CustomTextField
                        style={{ marginLeft: '6px', flex: 3 }}
                        label='Numero fattura'
                        variant='outlined'
                        value={data.invoiceNumber}
                        keepMounted={true}
                        onChange={(e) => {
                            setData((d: any) => {
                                return {
                                    ...d,
                                    invoiceNumber: e.target.value
                                };
                            });
                        }}
                        disabled={isLoading}
                    />
                </div>

                {!(data?.invoiceNumber ?? '').trim() && (
                    <>
                        <div style={{ backgroundColor: '#e8f4fd', borderRadius: '4px', paddingTop: '10px', paddingLeft: '8px', paddingRight: '8px', marginLeft: '-8px', marginRight: '-8px' }}>
                            <div style={{ display: 'flex' }}>
                                <CustomTextField
                                    style={{ marginRight: '3px', flex: 1 }}
                                    label='Rimanenza'
                                    variant='outlined'
                                    value={rimanenza5k.toFixed(2)}
                                    keepMounted={true}
                                    disabled={isLoading}
                                    inputProps={{ readOnly: true }}
                                />

                                <CustomTextField
                                    style={{ marginLeft: '3px', marginRight: '3px', flex: 1 }}
                                    label='Sforamento'
                                    variant='outlined'
                                    value={overflow.toFixed(2)}
                                    keepMounted={true}
                                    disabled={isLoading}
                                    inputProps={{ readOnly: true }}
                                />

                                <CustomTextField
                                    style={{ marginLeft: '3px', flex: 1 }}
                                    label='Netto'
                                    variant='outlined'
                                    value={net.toFixed(2)}
                                    keepMounted={true}
                                    disabled={isLoading}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>

                        {overflow > 0.001 && (
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                <CustomTextField
                                    style={{ marginRight: '6px' }}
                                    label='Tasse collaboratore'
                                    variant='outlined'
                                    required
                                    value={data.trainerTaxes}
                                    keepMounted={true}
                                    onChange={(e) => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                trainerTaxes: e.target.value
                                            };
                                        });
                                    }}
                                    onBlur={() => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                trainerTaxes: Math.max(((typeof d.trainerTaxes === 'string') ? parseFloat(d.trainerTaxes.replaceAll(',', '.')) : d.trainerTaxes), 0).toFixed(2)
                                            };
                                        });
                                    }}
                                    disabled={isLoading}
                                />

                                <CustomTextField
                                    style={{ marginRight: '6px' }}
                                    label='Tasse E.S'
                                    variant='outlined'
                                    required
                                    value={data.esTaxes}
                                    keepMounted={true}
                                    onChange={(e) => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                esTaxes: e.target.value
                                            };
                                        });
                                    }}
                                    onBlur={() => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                esTaxes: Math.max(((typeof d.esTaxes === 'string') ? parseFloat(d.esTaxes.replaceAll(',', '.')) : d.esTaxes), 0).toFixed(2)
                                            };
                                        });
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                        )}
                    </>
                )}

                <div style={{ marginTop: '0px', marginBottom: '4px' }} />

                {overflow > 0.001 && !(data?.invoiceNumber ?? '').trim() && (
                    <Alert severity="warning" style={{ marginBottom: '12px' }}>
                        <AlertTitle>Attenzione!</AlertTitle>
                        {rimanenza5k < 0.001 ? (
                            <>
                                L'istruttore ha superato
                            </>
                        ) : (
                            <>
                                Con la registrazione di questo pagamento, l'istruttore supererà
                            </>
                        )}
                        {' '}la soglia dei <b>€5000</b> annuali.
                        <p style={{ marginTop: '6px', marginBottom: '0' }}>
                            È necessario compilare i campi <b>"Tasse collaboratore"</b> e <b>"Tasse E.S"</b>.
                        </p>
                    </Alert>
                )}

                {overflow15k > 0.001 && !(data?.invoiceNumber ?? '').trim() && (
                    <Alert severity="error" style={{ marginBottom: '12px' }}>
                        <AlertTitle>Attenzione!</AlertTitle>
                        {rimanenza15k < 0.001 ? (
                            <>
                                L'istruttore ha superato
                            </>
                        ) : (
                            <>
                                Con la registrazione di questo pagamento, l'istruttore supererà
                            </>
                        )}
                        {' '}la soglia dei <b>€15000</b> annuali.
                    </Alert>
                )}
            </>
        );
    }, [data, isLoading, savedData, selectedPeriodsDesc]);

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Nuovo pagamento'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
                {isLoading ? (
                    <Alert severity='info' style={{ marginBottom: '16px' }}>
                        Caricamento in corso...
                    </Alert>
                ) : (dialogContent)}
            </CustomDialogWrapper>

            <SelectPeriodsDialog
                open={isSelectPeriodsDialogOpen}
                closeDialog={() => setIsSelectPeriodsDialogOpen(false)}
                periods={savedData?.periods ?? []}
                selectedPeriods={sortedSelectedPeriods}
                selectPeriod={selectPeriod}
            />
        </>
    );
};

export default AddPaymentDialog;
