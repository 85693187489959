import { useRef, useEffect, useState, useCallback } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';
import 'moment/locale/it';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../common/utils/snackbar';
import { MenuItem } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    courseLocationId?: string;
    courseLocationName?: string;
    type: 'standard' | 'cleaning';
}


const SummerFacilityCostDialog = (props: Props) => {
    const { open, closeDialog, courseLocationName, courseLocationId, type } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [cost, setCost] = useState('0.00');
    const [costType, setCostType] = useState('weekly');

    const apiRequestId = useRef('');

    useEffect(() => {
        if (!courseLocationId || !type || !open) return;

        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/summer_facilities/' + courseLocationId, 'GET').then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            if (type === 'standard') {
                setCost(parseFloat(res.amount).toFixed(2));
                setCostType(res.costType);
            } else {
                setCost(parseFloat(res.cleaningCost).toFixed(2));
            }

            setIsLoading(false);
        })
    }, [courseLocationId, type, open]);

    useEffect(() => {
        if (!open) return;

        setCost('0.00');
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/summer_facilities/' + courseLocationId, 'POST', { cost, type, costType }).then(res => {
            setIsLoading(false);
            snackbar.success('Costo salvato con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, courseLocationId, type, cost, costType]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={courseLocationName ?? ''} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>

                <CustomTextField
                    label={type === 'standard' ? 'Costo palestra' : 'Costo pulizia palestra'}
                    variant='outlined'
                    size='small'
                    value={cost === 'NaN' ? '0.00' : cost}
                    keepMounted={true}
                    onChange={(e) => {
                        setCost(e.target.value);
                    }}
                    onBlur={() => {
                        setCost((val: any) => {
                            return Math.max(((typeof val === 'string') ? parseFloat(val.replaceAll(',', '.')) : val), -Infinity).toFixed(2);
                        });
                    }}
                    disabled={isLoading}
                />

                {type === 'standard' && (
                    <CustomTextField
                        select
                        label={'Tipologia costo'}
                        value={costType}
                        onChange={(e: any) => {
                            setCostType(e.target.value);
                        }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        style={{ marginLeft: '6px ' }}
                        disabled={isLoading}
                    >
                        <MenuItem value={'weekly'} style={{ whiteSpace: 'break-spaces' }}>
                            settimanale
                        </MenuItem>
                        <MenuItem value={'yearly'} style={{ whiteSpace: 'break-spaces' }}>
                            annuale
                        </MenuItem>
                    </CustomTextField>
                )}
            </div>
        </CustomDialogWrapper >
    );
};

export default SummerFacilityCostDialog;
