import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import { ReduxState } from '../../../../../../redux/types';
import GenericPersonForm, { FormSection } from '../../components/GenericPersonForm/GenericPersonForm';
import { Props as FieldsHookProps } from '../../components/GenericPersonForm/hooks/useFields';
import StepFooter from '../../components/StepFooter/StepFooter';
import useValidatePersonStep from '../../hooks/useValidatePersonStep/useValidatePersonStep';
import { GenericStepProps } from '../types';
import InfoAlert from './components/InfoAlert/InfoAlert';

const formSections: FormSection[] = ['basic_data', 'phone', 'email'];

const RegistrationStep3 = (props: GenericStepProps) => {
    const { courseId, rawOnChange, validationResult, data, onChange, prevStep, nextStep, courseData, gtmStepPush, selectedLocationId, preselectedLocation } = props;
    const userInfo = useSelector((state: ReduxState) => state.user);

    const [isLoading, setIsLoading] = useState(false);
    const [showMessageDialog] = useMessageDialog();

    useEffect(() => {
        const campTown = (courseData?.locations?.find((l: any) => l.id === selectedLocationId) ?? preselectedLocation)?.town?.name;

        const analyticsData = {
            visitorLoggedIn: '1',
            summer_camp: courseData?.name ?? '',
            camp_town: campTown ?? '',
            student_country: 'Italy',
            student_city: data?.participant?.address?.town_name ?? '',
            student_zip: data?.participant?.address?.zip ?? '',
        };

        gtmStepPush(2, analyticsData);
    }, [gtmStepPush, courseData, selectedLocationId, preselectedLocation, data]);

    const fieldsHookProps: FieldsHookProps = {
        data,
        validationResult,
        formPrefix: 'second_parent',
        sections: formSections
    };

    const isStepFilled = useMemo(() => {
        const fields = ['name', 'surname', 'phone', 'email'];

        if (!data?.second_parent) return false;

        return fields.some(field => {
            return data.second_parent[field] ?? false;
        });
    }, [data]);

    const validatePersonStep = useValidatePersonStep({
        courseId,
        ...fieldsHookProps
    });

    const internalNextStep = useCallback(() => {
        if (!isStepFilled && courseId !== '981b6667-d548-434e-b7ac-676eac174324') {
            rawOnChange('second_parent', null);
            nextStep();
            return;
        }

        setIsLoading(true);

        validatePersonStep().then(() => {
            const firstParentEmail = userInfo.email.toLowerCase().trim();
            const secondParentEmail = (data?.second_parent?.email ?? '').toLowerCase().trim();

            if (firstParentEmail === secondParentEmail) {
                showMessageDialog({
                    title: 'Errore validazione dati',
                    message: 'L\'indirizzo email del genitore 2 non può essere uguale a quello del genitore 1.'
                });
            } else {
                nextStep();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data, isStepFilled, nextStep, rawOnChange, showMessageDialog, userInfo, validatePersonStep, courseId]);

    return (
        <>
            {courseId !== '981b6667-d548-434e-b7ac-676eac174324' && <InfoAlert />}

            <GenericPersonForm
                rawOnChange={rawOnChange}
                dataOnChange={onChange}
                {...fieldsHookProps}
            />

            <StepFooter isLoading={isLoading} prevStep={prevStep} nextStep={internalNextStep} />
        </>
    );
};

export default RegistrationStep3;
