import { useCallback, useEffect, useMemo, useState } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import DeletedPaymentsDialog from './components/DeletedPaymentsDialog/DeletedPaymentsDialog';
import PaymentCard from './components/PaymentCard/PaymentCard';
import AddPaymentDialog from '../AddPaymentDialog/AddPaymentDialog';
import OtherAsdPayoutsDialog from './components/OtherAsdPayoutsDialog/OtherAsdPayoutsDialog';
import { Link } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: (changed?: boolean) => void;
    refreshRegistrations: () => void;
    superUserId?: string;
    season: 'summer' | 'winter' | 'winter_facilities' | 'summer_facilities';
    year?: number;
}

const initialData = {
    'paid': '0.00',
    'method': '',
    'invoiceNumber': ''
};

const PaymentsDialog = (props: Props) => {
    const { open, closeDialog, refreshRegistrations, superUserId, season, year } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [savedData, setSavedData] = useState<any>(initialData);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isAddPaymentDialogOpen, setIsAddPaymentDialogOpen] = useState(false);
    const [isDeletedPaymentDialogOpen, setIsDeletedPaymentDialogOpen] = useState(false);
    const [isOtherAsdPayoutsDialogOpen, setIsOtherAsdPayoutsDialogOpen] = useState(false);

    const [selectedYearOtherAsdPayouts, setSelectedYearOtherAsdPayouts] = useState(0);

    useEffect(() => {
        if (!superUserId || !open) return;

        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments', 'GET', { year }).then(res => {
            setSavedData(res);
            setIsLoading(false);
        });
    }, [open, superUserId, season, year]);

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments', 'GET', { year }).then(res => {
            setSavedData(res);
            setIsLoading(false);
        });
    }, [superUserId, season, year]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const deletePaymentConfirm = useCallback((paymentId: string) => {
        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments/' + paymentId, 'DELETE').then(() => {
            snackbar.success('Pagamento eliminato correttamente!');
            refreshData();
            refreshRegistrations();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshData, refreshRegistrations, superUserId, season]);

    const deletePayment = useCallback((payment: any) => {
        showMessageDialog({
            title: 'Elimina pagamento',
            message: (
                <>
                    Sei sicuro di volere eliminare il pagamento di <b>€{parseFloat(payment.amount).toFixed(2)}</b>{' '}
                    registrato in data <b>{moment(payment.createdAt).format('DD/MM/YYYY')}</b>?
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Elimina pagamento',
                    color: 'secondary',
                    action: () => {
                        closeMessageDialog();
                        deletePaymentConfirm(payment.id);
                    }
                }
            ]
        });
    }, [deletePaymentConfirm, showMessageDialog, closeMessageDialog]);

    const dialogContent = useMemo(() => {
        return (
            <>
                <TableContainer style={{ marginTop: '0px' }}>
                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px 8px' }}>istruttore</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px 8px' }} colSpan={3}>{savedData?.paymentStatus?.lastName} {savedData?.paymentStatus?.firstName}</TableCell>
                            </TableRow>

                            {[2023, 2024, 2025].map(loop_year => {
                                if ((loop_year === 2023 && season === 'winter') || (loop_year !== 2024 && season === 'summer' && year === 2024) || (loop_year !== 2025 && season === 'summer' && year === 2025)) return null;

                                const amount = (savedData?.otherAsdPayouts ?? []).reduce((prev: number, curr: any) => (prev + (parseInt(moment(curr.date).format('YYYY')) === year ? parseFloat(curr.amount) : 0.0)), 0.0);

                                if (amount < 0.001) return null;

                                return (
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', padding: '4px 8px' }}>
                                            <Link href='#' onClick={(e) => {
                                                e.preventDefault();
                                                setIsOtherAsdPayoutsDialogOpen(true);
                                                setSelectedYearOtherAsdPayouts(loop_year);
                                            }}>
                                                compensi altre ASD ({loop_year})
                                            </Link>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', padding: '4px 8px' }} colSpan={3}>€ {amount.toFixed(2)}</TableCell>
                                    </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell colSpan={4} style={{ padding: '12px' }} />
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ padding: '8px' }} />
                                <TableCell style={{ fontWeight: 'bold', textAlign: 'right', padding: '8px' }}>pagato</TableCell>
                                <TableCell style={{ fontWeight: 'bold', textAlign: 'right', padding: '8px' }}>da pagare</TableCell>
                                <TableCell style={{ fontWeight: 'bold', textAlign: 'right', padding: '8px' }}>totale</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '8px' }}>
                                    totale compensi
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                    € {((savedData?.paymentStatus?.totals[2023]?.paid ?? 0) + (savedData?.paymentStatus?.totals[2024]?.paid ?? 0) + (savedData?.paymentStatus?.totals[2025]?.paid ?? 0)).toFixed(2)}
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                    € {(parseFloat(savedData?.paymentStatus?.totalPayouts ?? 0) - ((savedData?.paymentStatus?.totals[2023]?.paid ?? 0) + (savedData?.paymentStatus?.totals[2024]?.paid ?? 0) + (savedData?.paymentStatus?.totals[2025]?.paid ?? 0))).toFixed(2)}
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                    € {(parseFloat(savedData?.paymentStatus?.totalPayouts ?? 0)).toFixed(2)}
                                </TableCell>
                            </TableRow>
                            {((((savedData?.paymentStatus?.totals[2023]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2024]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2025]?.trainerTaxes ?? 0)) > 0.001) ||
                                ((((savedData?.paymentStatus?.totals[2023]?.esTaxes ?? 0) + (savedData?.paymentStatus?.totals[2024]?.esTaxes ?? 0) + (savedData?.paymentStatus?.totals[2025]?.esTaxes ?? 0)) > 0.001))) && (
                                    <>
                                        <TableRow>
                                            <TableCell style={{ padding: '8px' }}>
                                                totale netto
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                                € {((savedData?.paymentStatus?.totals[2023]?.netAmount ?? 0) + (savedData?.paymentStatus?.totals[2024]?.netAmount ?? 0) + (savedData?.paymentStatus?.totals[2025]?.netAmount ?? 0)).toFixed(2)}
                                            </TableCell>
                                            <TableCell colSpan={2} style={{ padding: '8px' }} />
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: '8px' }}>
                                                tasse collaboratore
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                                € {((savedData?.paymentStatus?.totals[2023]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2024]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2025]?.trainerTaxes ?? 0)).toFixed(2)}
                                            </TableCell>
                                            <TableCell colSpan={2} style={{ padding: '8px' }} />
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: '8px' }}>
                                                tasse E.S
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                                € {((savedData?.paymentStatus?.totals[2023]?.esTaxes ?? 0) + (savedData?.paymentStatus?.totals[2024]?.esTaxes ?? 0) + (savedData?.paymentStatus?.totals[2025]?.esTaxes ?? 0)).toFixed(2)}
                                            </TableCell>
                                            <TableCell colSpan={2} style={{ padding: '8px' }} />
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: '8px' }}>
                                                totale lordo
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right', padding: '8px' }}>
                                                € {((savedData?.paymentStatus?.totals[2023]?.netAmount ?? 0) + (savedData?.paymentStatus?.totals[2024]?.netAmount ?? 0) + (savedData?.paymentStatus?.totals[2025]?.netAmount ?? 0) + (savedData?.paymentStatus?.totals[2023]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2024]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2025]?.trainerTaxes ?? 0) + (savedData?.paymentStatus?.totals[2023]?.esTaxes ?? 0) + (savedData?.paymentStatus?.totals[2024]?.esTaxes ?? 0) + (savedData?.paymentStatus?.totals[2025]?.esTaxes ?? 0)).toFixed(2)}
                                            </TableCell>
                                            <TableCell colSpan={2} style={{ padding: '8px' }} />
                                        </TableRow>
                                    </>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider style={{ marginTop: '0px', marginBottom: '16px' }} />

                {((savedData?.paymentStatus?.totalPayouts ?? 0) === (savedData?.paymentStatus?.paidPayouts ?? -1) ? (
                    <Alert severity='success' style={{ marginBottom: '16px' }}>
                        I compensi per l'istruttore sono stati interamente pagati!
                    </Alert>
                ) : (
                    <>
                        <Button onClick={() => setIsAddPaymentDialogOpen(true)} color={'primary'} variant={'contained'} style={{ margin: '0 auto', marginBottom: '14px', display: 'block' }}>
                            Registra pagamento
                        </Button>
                    </>
                ))}

                <Divider style={{ marginTop: '8px' }} />

                <div style={{ display: 'flex' }}>
                    <Typography variant='h5' style={{ margin: '12px 0', fontSize: '1.4em', flex: 1 }}>
                        Storico pagamenti
                    </Typography>
                </div>

                {!(savedData?.payments?.length) ? (
                    <Alert severity='warning'>
                        Nessun pagamento registrato
                    </Alert>
                ) : (
                    <>
                        {savedData.payments.map((payment: any) => (
                            <PaymentCard payment={payment} deletePayment={deletePayment} />
                        ))}
                    </>
                )}

            </>
        );
    }, [deletePayment, savedData, season, year]);

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Gestione pagamenti'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
                {isLoading ? (
                    <Alert severity='info' style={{ marginBottom: '16px' }}>
                        Caricamento in corso...
                    </Alert>
                ) : (dialogContent)}
            </CustomDialogWrapper>

            <DeletedPaymentsDialog
                open={isDeletedPaymentDialogOpen}
                closeDialog={() => setIsDeletedPaymentDialogOpen(false)}
                payments={savedData?.deletedPayments ?? []}
            />

            <AddPaymentDialog
                open={isAddPaymentDialogOpen}
                closeDialog={() => setIsAddPaymentDialogOpen(false)}
                superUserId={superUserId}
                season={season}
                refreshData={() => {
                    refreshData();
                    refreshRegistrations();
                }}
                year={year}
            />

            <OtherAsdPayoutsDialog
                open={isOtherAsdPayoutsDialogOpen}
                closeDialog={() => setIsOtherAsdPayoutsDialogOpen(false)}
                payouts={savedData?.otherAsdPayouts ?? []}
                year={selectedYearOtherAsdPayouts}
            />
        </>
    );
};

export default PaymentsDialog;
