import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, CircularProgress, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import PersonRegistrationsDialog from './components/PersonRegistrationsDialog/PersonRegistrationsDialog';
import { StyledTablePagination, Title } from './styled';
import PersonDataDialog from '../../../public/pages/registrationsListPage/components/PersonDataDialog/PersonDataDialog';

const PeopleListPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [paginationPage, setPaginationPage] = useState(0);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(25);

    const [people, setPeople] = useState<GenericObject[]>([]);
    const [query, setQuery] = useState('');
    const [loadedQuery, setLoadedQuery] = useState('');
    const [loadedPage, setLoadedPage] = useState(0);
    const [loadedItemsPerPage, setLoadedItemsPerPage] = useState(25);
    const [internalLoading, setInternalLoading] = useState(false);
    const [debouncedQuery] = useDebounce(query, 500);

    const [isPersonDataDialogOpen, setIsPersonDataDialogOpen] = useState(false);
    const [isRegistrationDialogOpen, setIsRegistrationDialogOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<GenericObject>({});

    const apiRequestId = useRef('');

    useEffect(() => {
        setInternalLoading(query !== loadedQuery || paginationPage !== loadedPage || paginationItemsPerPage !== loadedItemsPerPage);
    }, [query, loadedQuery, paginationPage, loadedPage, paginationItemsPerPage, loadedItemsPerPage]);

    useEffect(() => {
        setPaginationPage(0);
    }, [debouncedQuery]);

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/people', 'GET', { q: debouncedQuery, page: paginationPage + 1, perPage: paginationItemsPerPage }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            const { data, total, lastPage } = res;
            setPeople(data);
            setPaginationTotal(total);
            setLoadedQuery(debouncedQuery);
            setLoadedPage(paginationPage);
            setLoadedItemsPerPage(paginationItemsPerPage);

            if (paginationPage + 1 > lastPage) {
                setPaginationPage(lastPage - 1);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [debouncedQuery, setIsLoading, paginationPage, paginationItemsPerPage]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const isMobile = width <= 700;

    return (
        <>
            <Title isMobile={isMobile}>
                Elenco utenti
            </Title>

            <div style={{ display: 'flex' }}>
                <TextField
                    label='Ricerca utenti'
                    variant='outlined'
                    placeholder='Ricerca per nome, cognome, codice fiscale o indirizzo email'
                    value={query}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                    }}
                    style={{ width: '100%', maxWidth: '600px' }}
                />

                {internalLoading && (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                        <CircularProgress />
                    </div>
                )}
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Codice fiscale</TableCell>
                            <TableCell>Data di nascita</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Da pagare</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Dettagli</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {people.map(person => {
                            return (
                                <TableRow>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : person.surname}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : person.name}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : person.taxCode}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : (person.dateOfBirth ? moment(person.dateOfBirth).format('DD/MM/YYYY') : '')}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{internalLoading ? <Skeleton variant='text' /> : ('€ ' + parseFloat(person.toPayAmount).toFixed(2))}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', height: '44px' }}>
                                        {internalLoading ? <Skeleton variant='text' /> : (
                                            <>
                                                <Button size='small' variant='outlined' color='primary' onClick={() => {
                                                    setSelectedPerson(person);
                                                    setIsPersonDataDialogOpen(true);
                                                }}>
                                                    Visualizza anagrafica
                                                </Button>{' '}

                                                <Button size='small' variant='outlined' color='primary' disabled={!person.registrationCount} onClick={() => {
                                                    setSelectedPerson(person);
                                                    setIsRegistrationDialogOpen(true);
                                                }}>
                                                    Visualizza iscrizioni
                                                </Button>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <StyledTablePagination
                count={paginationTotal}
                page={paginationPage}
                onPageChange={(e: any, page: number) => setPaginationPage(page)}
                rowsPerPage={paginationItemsPerPage}
                onChangeRowsPerPage={(e: any) => setPaginationItemsPerPage(parseInt(e.target.value, 10))}
                // @ts-ignore
                component='div'
                backIconButtonText='Pagina precedente'
                nextIconButtonText='Pagina successiva'
                labelRowsPerPage='Righe'
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from} - ${to} di ${count}`;
                }}
            />

            <PersonRegistrationsDialog
                open={isRegistrationDialogOpen}
                closeDialog={() => setIsRegistrationDialogOpen(false)}
                refreshData={refreshData}
                person={selectedPerson}
            />

            <PersonDataDialog
                isOpen={isPersonDataDialogOpen}
                onClose={() => setIsPersonDataDialogOpen(false)}
                personId={selectedPerson?.id ?? ''}
            />
        </>
    );
};

export default PeopleListPage;
