import GenericObject from "../../../../../../../../typesAdditional/GenericObject";
import TextField from "@material-ui/core/TextField";
import { useCallback, useMemo, useState } from "react";
import TownAutoComplete from "../../../../components/GenericPersonForm/sections/address/components/TownAutocomplete/TownAutocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useWindowSize } from "react-use";

interface Props {
    data: GenericObject;
    rawOnChange: (field: string, value: any) => void;
}

const AquiloneDetails = (props: Props) => {
    const { data, rawOnChange } = props;

    const { width } = useWindowSize();

    const numeroCartaIdentita = data?._additionals?.numeroCartaIdentita;

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const dummyTownData = useMemo(() => {
        return {
            dummy: {
                address: {
                    town_legal_id: data?._additionals?.townLegalId ?? null,
                }
            }
        };
    }, [data]);

    const dummyTownRawOnChange = useCallback((path: string, value: string) => {
        if (path === 'dummy.address.town_legal_id') {
            rawOnChange('_additionals.townLegalId', value);
        }

        if (path === 'dummy.address.town_name') {
            rawOnChange('_additionals.townName', value);
        }
    }, [rawOnChange]);

    return (
        <div>
            <div style={{ width: '100%', display: width > 900 ? 'flex' : undefined }}>
                <TextField
                    label='Numero carta identità del minore'
                    variant='outlined'
                    value={numeroCartaIdentita}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => rawOnChange('_additionals.numeroCartaIdentita', e.target.value.replace(/[\W_]+/g, "").toUpperCase())}
                    style={{ flex: 1, margin: '0 6px' }}
                    inputProps={{ maxLength: 9 }}
                    fullWidth
                    error={numeroCartaIdentita && !/[A-Z]{2}[0-9]{5}[A-Z]{2}/.test(numeroCartaIdentita) && !/[A-Z]{2}[0-9]{7}/.test(numeroCartaIdentita)}
                    helperText={(numeroCartaIdentita && !/[A-Z]{2}[0-9]{5}[A-Z]{2}/.test(numeroCartaIdentita) && !/[A-Z]{2}[0-9]{7}/.test(numeroCartaIdentita)) ? 'Numero non corretto' : undefined}
                />

            </div>

            <div style={{ width: '100%', display: width > 900 ? 'flex' : undefined }}>
                <TownAutoComplete overrideLabel='Comune di rilascio' data={dummyTownData} formPrefix={'dummy'} rawOnChange={dummyTownRawOnChange} style={{ margin: '0 6px', flex: 1, marginTop: '14px' }} />

                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardDatePicker
                        label='Scadenza'
                        inputVariant='outlined'
                        format='DD/MM/YYYY'
                        value={data?._additionals?.rawDocumentExpireDate ?? null}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            rawOnChange('_additionals.rawDocumentExpireDate', newDate?.format('YYYY-MM-DD') ?? '');
                            rawOnChange('_additionals.documentExpireDate', newDate?.format('DD/MM/YYYY') ?? '');
                        }}
                        open={isDatePickerOpen}
                        onClick={() => {
                            setIsDatePickerOpen(true);
                        }}
                        onClose={() => setIsDatePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, margin: '0 6px', marginTop: '14px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                        minDate={'2025-07-05'}
                        maxDate={moment(Date.now()).add(10, 'years').format('YYYY-MM-DD')}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

export default AquiloneDetails;