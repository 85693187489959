import { useEffect, useRef, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

import api from '../../../../../../../../../../common/utils/api';
import GenericObject from '../../../../../../../../../../typesAdditional/GenericObject';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    data: GenericObject;
    formPrefix: string;
    rawOnChange: (field: string, value: any) => void;
    overrideLabel?: string;
    style?: any;
}

const TownAutoComplete = (props: Props) => {
    const { data, formPrefix, rawOnChange, overrideLabel, style } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [towns, setTowns] = useState([]);

    const [value, setValue] = useState('0');

    const apiRequestId = useRef('');

    const selectedTownId = _.get(data, formPrefix)?.address?.town_legal_id ?? '';

    const setSelectedTownId = (town: string) => {
        rawOnChange(formPrefix + '.address.town_legal_id', town);

        if (town) {
            api.request('/towns/' + town).then(res => {
                rawOnChange(formPrefix + '.address.town_name', res?.name);
            });
        } else {
            rawOnChange(formPrefix + '.address.town_name', '');
        }

    };

    useEffect(() => {
        setIsLoading(true);

        const timeout = setTimeout(() => {
            const currentRequestId = uuidv4();
            apiRequestId.current = currentRequestId;

            api.request('/towns/search', 'GET', { query: (selectedTownId.length && value === '0') ? selectedTownId : value }).then(res => {
                if (currentRequestId !== apiRequestId.current) return;

                setTowns(res);
                setIsLoading(false);
            });
        }, 250);

        return () => clearTimeout(timeout);
    }, [selectedTownId, value]);

    const onChange = (_e: any, option?: any) => {
        setSelectedTownId(option?.value ?? '');
    };

    const onInputChange = (e: any) => {
        if (!e) {
            return;
        } else if (e.target.value === undefined) {
            setSelectedTownId('');
        }

        setValue(e.target.value === 0 ? '0' : e.target.value);
    };

    const options = towns.map((town: any) => {
        return {
            name: town.name + ' (' + town.province.abbreviation + ')',
            value: town.legalId
        };
    });

    const selectedOption = options.find((t: any) => t.value === selectedTownId) ?? null;

    return (
        <Autocomplete
            onChange={onChange}
            onInputChange={onInputChange}
            open={isOpen}
            style={{ flex: 1, marginBottom: '12px', ...style }}
            onOpen={() => {
                setIsOpen(true);
            }}
            onClose={() => {
                setIsOpen(false);
            }}
            fullWidth
            getOptionSelected={(option1, option2) => option1.value === option2.value}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={isLoading}
            clearText='Rimuovi'
            closeText='Chiudi'
            openText='Apri'
            loadingText='Caricamento...'
            value={selectedOption}
            noOptionsText={!value?.length ? 'Inserisci il nome del paese' : 'Nessun risultato trovato'}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={overrideLabel ?? 'Paese di residenza'}
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default TownAutoComplete;
