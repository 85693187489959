import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Card, CardContent, Divider, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import { PageContainer } from './styled';
import moment from 'moment';
import 'moment/locale/it';
import { ReduxState } from '../../../../redux/types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import SummerWeeksDetailDialog from './components/SummerWeeksDetailDialog/SummerWeeksDetailDialog';
import React from 'react';
import WinterScheduleDialog from '../trainersListPage/components/WinterScheduleDialog/WinterScheduleDialog';
import AvailabilityDetailDialog from '../trainersListPage/components/AvailabilityDetailDialog/AvailabilityDetailDialog';
import AddOtherAsdPayoutDialog from './components/AddOtherAsdPayoutDialog/AddOtherAsdPayoutDialog';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import snackbar from '../../../../common/utils/snackbar';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    plugins: {
        title: {
            display: false,
        },
        tooltip: {
            mode: 'index' as 'index',
            callbacks: {
                label: function (context: any) {
                    let label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(context.parsed.y);
                    }
                    return label;
                }
            },
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            ticks: {
                beginAtZero: true,
                callback: function (value: any, index: any, values: any) {
                    return '€' + value;
                }
            },
        },
    },
    tooltips: {
        // Overrides the global setting
        mode: 'label'
    }
};

const labels = ['settembre', 'ottobre', 'novembre', 'dicembre', 'gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno'];

interface Props {
    season: 'summer' | 'winter';
}

const TYPE_DESC = {
    summer_credits: 'Rimanenze estate',
    winter_credits: 'Rimanenze inverno',
    extra_work: 'Lavori extra estate',
    bonus: 'Premi',
    winter_extra_work: 'Lavori extra inverno',
    winter_competitions: 'Gare',
    winter_bonus: 'Bonus'
};

const TrainerPayoutsReportTable = ({ season }: Props) => {
    const { trainerId, year } = useParams<{ trainerId?: string, year: string }>();

    const setIsLoading = useInternalLoader();
    const [isDeletingOtherAsdPayout, setIsDeletingOtherAsdPayout] = useState(false);

    const { width } = useWindowSize();

    const [info, setInfo] = useState<any>({});

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = userInfo?.administrator ?? false;

    const [computeUntil, setComputeUntil] = useState(isAdmin ? 'eoy' : 'today');

    const realTrainerId = trainerId ?? userInfo.id;

    const [isSummerWeekDetailDialogOpen, setIsSummerWeekDetailDialogOpen] = useState(false);
    const [isWinterScheduleDialogOpen, setIsWinterScheduleDialogOpen] = useState(false);
    const [isAvailabilityDetailDialogOpen, setIsAvailabilityDetailDialogOpen] = useState(false);
    const [isAddOtherAsdPayoutDialogOpen, setIsAddOtherAsdPayoutDialogOpen] = useState(false);

    const history = useHistory();

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const firstYearTotalAmount = useMemo(() => {
        let total = 0.0;

        (info?.winter?.monthPayouts ?? []).forEach((month: any) => {
            if (month.month >= 9) {
                total += month.payouts.reduce((prev: any, curr: any) => prev + curr.amount, 0);
            }
        })

        total += parseFloat((info?.winter?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0));

        return total;
    }, [info]);

    const secondYearTotalAmount = useMemo(() => {
        let total = 0.0;

        (info?.winter?.monthPayouts ?? []).forEach((month: any) => {
            if (month.month < 9) {
                total += month.payouts.reduce((prev: any, curr: any) => prev + curr.amount, 0);
            }
        })

        total += parseFloat((info?.summer?.monthPayouts ?? []).reduce((prev: any, curr: any) => prev + curr.payout, 0));
        total += parseFloat((info?.summer?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0));

        return total;
    }, [info]);

    const refreshData = useCallback((showLoader?: boolean) => {
        if (showLoader) setIsLoading(true);

        api.request('/admin/recap_payouts/' + realTrainerId, 'GET', { year, computeUntil }).then(res => {
            setInfo(res);
            setIsLoading(false);
        })
    }, [setIsLoading, realTrainerId, year, computeUntil]);

    const deleteOtherAsdPayout = useCallback((payoutId: string) => {
        showMessageDialog({
            title: 'Elimina compenso',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di volere eliminare il compenso selezionato?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        setIsDeletingOtherAsdPayout(true);

                        closeMessageDialog();

                        api.request('/admin/other_asd_payouts/' + realTrainerId + '/' + payoutId, 'DELETE').then(() => {
                            snackbar.success('Compenso eliminato con successo!');

                            refreshData(true);
                        }).finally(() => {
                            setIsDeletingOtherAsdPayout(false);
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, refreshData, realTrainerId]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const isMobile = width <= 700;

    console.log('X1', info?.summer?.totals ?? null);
    console.log('X2', info?.summer?.totals[parseInt(year)]);

    const chartData = useMemo(() => {
        return {
            labels,
            datasets: [
                {
                    label: 'da pagare',
                    data: info?.winter?.paidPayouts.map((payout: any) => {
                        return payout.total - payout.paid
                    }),
                    backgroundColor: '#abcbad',
                    order: 2
                },
                {
                    label: 'pagato',
                    data: info?.winter?.paidPayouts.map((payout: any) => {
                        return payout.paid
                    }),
                    backgroundColor: '#2E7D32',
                    order: 1
                },
            ],
        };
    }, [info?.winter?.paidPayouts]);

    return (
        <PageContainer>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        {info?.superUser ? (info.superUser.lastName + ' ' + info.superUser.firstName) : ''}
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                            report compensi
                        </Typography>
                    </div>

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    {year === '2024' && (
                        <CustomTextField
                            select
                            label={'Calcola compensi fino a'}
                            value={computeUntil}
                            variant='outlined'
                            onChange={(e: any) => {
                                setComputeUntil(e.target.value);
                            }}
                            style={{ width: '180px', marginBottom: 0, marginRight: '16px' }}
                        >
                            <MenuItem value={'today'} style={{ whiteSpace: 'break-spaces' }}>
                                oggi
                            </MenuItem>

                            <MenuItem value={'eoy'} style={{ whiteSpace: 'break-spaces' }}>
                                giugno 2025
                            </MenuItem>
                        </CustomTextField>
                    )}

                    <CustomTextField
                        select
                        label={'Anno'}
                        value={year}
                        onChange={(e: any) => {
                            history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + (isAdmin ? ('/staff-winter/payouts-report/' + realTrainerId) : ('/payouts-report')) + '/' + e.target.value);
                        }}
                        variant='outlined'
                        style={{ width: '160px', marginBottom: 0 }}
                    >
                        <MenuItem value={2023} style={{ whiteSpace: 'break-spaces' }}>
                            2023/2024
                        </MenuItem>

                        <MenuItem value={2024} style={{ whiteSpace: 'break-spaces' }}>
                            2024/2025
                        </MenuItem>
                    </CustomTextField>

                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <div style={{ display: width > 1100 ? 'flex' : undefined, flexGrow: 1, overflowY: 'auto', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <Card style={{ margin: '8px 8px 16px' }}>
                        <CardContent>
                            <Typography variant='h1' style={{ fontSize: '1.8em', textAlign: 'center', margin: '-16px -16px 8px -16px', backgroundColor: '#edf7ed', padding: '12px 8px' }}>
                                INVERNO {parseInt(year)}-{parseInt(year) + 1}
                            </Typography>

                            <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                <Table size='small'>
                                    {(info?.winter?.wages ?? []).map((wage: any, idx: number) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{wage.usedWageDesc + ((info?.winter?.wages.findIndex((x: any) => x.usedWageDesc === wage.usedWageDesc) < idx) ? (' (dal ' + moment(wage.startDate).format('DD/MM/YYYY') + ')') : '')}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>{parseFloat(wage.hourlyWage) % 1 === 0 ? parseFloat(wage.hourlyWage) : parseFloat(wage.hourlyWage).toFixed(2)}</TableCell>
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>€/{wage.costTimeUnit === 'hourly' ? 'ora' : 'mese'}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </Table>
                            </TableContainer>

                            {(info?.winter?.monthPayouts ?? []).map((month: any) => {
                                return (
                                    <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                                    <TableCell>{moment().month(month.month - 1).format("MMMM")}</TableCell>
                                                    <TableCell style={{ width: '10px', textAlign: 'right' }}>ore</TableCell>
                                                    <TableCell style={{ width: '100px', textAlign: 'right' }}>compenso</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {month.payouts.map((payout: any) => {
                                                    const hours = Math.round(payout.hours * 100) / 100;
                                                    const amount = Math.round(payout.amount * 100) / 100;

                                                    return (
                                                        <TableRow>
                                                            <TableCell>{payout.name}</TableCell>
                                                            <TableCell style={{ width: '100px', textAlign: 'right' }}>{hours % 1 === 0 ? hours : hours.toFixed(2)}</TableCell>
                                                            <TableCell style={{ width: '100px', textAlign: 'right' }}>€ {amount % 1 === 0 ? amount : amount.toFixed(2)}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                                {month.payouts.length > 1 && (
                                                    <TableRow>
                                                        <TableCell><b>Totale</b></TableCell>
                                                        <TableCell style={{ width: '100px', textAlign: 'right' }}><b>{parseFloat(month.payouts.reduce((prev: any, curr: any) => prev + curr.hours, 0)) % 1 === 0 ? parseFloat(month.payouts.reduce((prev: any, curr: any) => prev + curr.hours, 0)) : parseFloat(month.payouts.reduce((prev: any, curr: any) => prev + curr.hours, 0)).toFixed(2)}</b></TableCell>
                                                        <TableCell style={{ width: '100px', textAlign: 'right' }}><b>€ {parseFloat(month.payouts.reduce((prev: any, curr: any) => prev + curr.amount, 0)) % 1 === 0 ? parseFloat(month.payouts.reduce((prev: any, curr: any) => prev + curr.amount, 0)) : parseFloat(month.payouts.reduce((prev: any, curr: any) => prev + curr.amount, 0)).toFixed(2)}</b></TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            })}

                            <Divider style={{ marginTop: '16px' }} />

                            <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                            <TableCell><b>TOTALE {parseInt(year) % 100}-{(parseInt(year) + 1) % 100}</b></TableCell>
                                            <TableCell style={{ width: '100px', textAlign: 'right' }}><b>{parseFloat((info?.winter?.monthPayouts ?? []).flatMap(((m: any) => m.payouts)).reduce((prev: any, curr: any) => prev + curr.hours, 0)) % 1 === 0 ? parseFloat((info?.winter?.monthPayouts ?? []).flatMap(((m: any) => m.payouts)).reduce((prev: any, curr: any) => prev + curr.hours, 0)) : parseFloat((info?.winter?.monthPayouts ?? []).flatMap(((m: any) => m.payouts)).reduce((prev: any, curr: any) => prev + curr.hours, 0)).toFixed(2)}</b></TableCell>
                                            <TableCell style={{ width: '100px', textAlign: 'right' }}><b>€ {parseFloat((info?.winter?.monthPayouts ?? []).flatMap(((m: any) => m.payouts)).reduce((prev: any, curr: any) => prev + curr.amount, 0)) % 1 === 0 ? parseFloat((info?.winter?.monthPayouts ?? []).flatMap(((m: any) => m.payouts)).reduce((prev: any, curr: any) => prev + curr.amount, 0)) : parseFloat((info?.winter?.monthPayouts ?? []).flatMap(((m: any) => m.payouts)).reduce((prev: any, curr: any) => prev + curr.amount, 0)).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {!!(info?.winter?.extraWages ?? []).filter((x: any) => !!x.amount).length && (
                                <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                    <Table size='small'>
                                        {(info?.winter?.extraWages ?? []).filter((x: any) => !!x.amount).map((wage: any) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{TYPE_DESC[wage.type as keyof typeof TYPE_DESC]}</TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>€ {parseFloat(wage.amount) % 1 === 0 ? parseFloat(wage.amount) : parseFloat(wage.amount).toFixed(2)}</TableCell>
                                                </TableRow>
                                            )
                                        })}

                                        {(info?.winter?.extraWages ?? []).filter((x: any) => !!x.amount).length > 1 && (
                                            <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                                <TableCell><b>TOTALE</b></TableCell>
                                                <TableCell style={{ textAlign: 'right' }}><b>€ {parseFloat((info?.winter?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0)) % 1 === 0 ? parseFloat((info?.winter?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0)) : parseFloat((info?.winter?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0)).toFixed(2)}</b></TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </TableContainer>
                            )}

                            <div style={{ height: '300px', margin: '24px 0' }}>
                                <Bar
                                    options={options}
                                    data={chartData}
                                />
                            </div>

                            <div style={{ textAlign: 'center', marginTop: '20px', marginRight: '4px' }} >
                                <Button onClick={() => setIsWinterScheduleDialogOpen(true)} variant='outlined' color='primary' disabled={parseInt(year) !== 2024}>
                                    Visualizza impiego settimanale
                                </Button>

                                <Button style={{ marginLeft: '4px' }} onClick={() => {
                                    if (isAdmin) {
                                        history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/staff-winter/detailed-timetable/' + realTrainerId + '/9');
                                    } else {
                                        history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/winter-schedule/timetable/9');
                                    }
                                }} variant='outlined' color='primary' disabled={parseInt(year) !== 2024}>
                                    Visualizza dettaglio ore
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                    <div style={{ fontSize: '0.01em' }}>&nbsp;</div>
                </div>
                <div style={{ flex: 1 }}>
                    <Card style={{ margin: '8px 8px 16px' }}>
                        <CardContent>
                            <Typography variant='h1' style={{ fontSize: '1.8em', textAlign: 'center', margin: '-16px -16px 8px -16px', backgroundColor: '#edf7ed', padding: '12px 8px' }}>
                                ESTATE {parseInt(year) + 1}
                            </Typography>

                            <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                            <TableCell></TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>tecnico</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>responsabile</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>compenso</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(info?.summer?.monthPayouts ?? []).map((month: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{moment().month(month.month - 1).format("MMMM")}</TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>{month.notResponsible}</TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>{month.responsible}</TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>€ {parseFloat(month.payout) % 1 === 0 ? parseFloat(month.payout) : parseFloat(month.payout).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                        <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                            <TableCell><b>TOTALE</b></TableCell>
                                            <TableCell style={{ textAlign: 'right' }}><b>{(info?.summer?.monthPayouts ?? []).reduce((prev: any, curr: any) => prev + curr.notResponsible, 0)}</b></TableCell>
                                            <TableCell style={{ textAlign: 'right' }}><b>{(info?.summer?.monthPayouts ?? []).reduce((prev: any, curr: any) => prev + curr.responsible, 0)}</b></TableCell>
                                            <TableCell style={{ textAlign: 'right' }}><b>€ {parseFloat((info?.summer?.monthPayouts ?? []).reduce((prev: any, curr: any) => prev + curr.payout, 0)) % 1 === 0 ? parseFloat((info?.summer?.monthPayouts ?? []).reduce((prev: any, curr: any) => prev + curr.payout, 0)) : parseFloat((info?.summer?.monthPayouts ?? []).reduce((prev: any, curr: any) => prev + curr.payout, 0)).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {!!(info?.summer?.extraWages ?? []).filter((x: any) => !!x.amount).length && (
                                <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                    <Table size='small'>
                                        {(info?.summer?.extraWages ?? []).filter((x: any) => !!x.amount).map((wage: any) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{TYPE_DESC[wage.type as keyof typeof TYPE_DESC]}</TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>€ {parseFloat(wage.amount) % 1 === 0 ? parseFloat(wage.amount) : parseFloat(wage.amount).toFixed(2)}</TableCell>
                                                </TableRow>
                                            )
                                        })}

                                        {(info?.summer?.extraWages ?? []).filter((x: any) => !!x.amount).length > 1 && (
                                            <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                                <TableCell><b>TOTALE</b></TableCell>
                                                <TableCell style={{ textAlign: 'right' }}><b>€ {parseFloat((info?.summer?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0)) % 1 === 0 ? parseFloat((info?.summer?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0)) : parseFloat((info?.summer?.extraWages ?? []).reduce((prev: any, curr: any) => prev + curr.amount, 0)).toFixed(2)}</b></TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </TableContainer>
                            )}

                            <div style={{ textAlign: 'center', marginTop: '20px' }} >
                                <Button onClick={() => setIsSummerWeekDetailDialogOpen(true)} variant='outlined' color='primary' disabled={(info?.summer?.weeklyPayouts ?? []).length === 0} style={{ marginRight: '4px' }}>
                                    Dettaglio settimane
                                </Button>

                                <Button onClick={() => setIsAvailabilityDetailDialogOpen(true)} variant='outlined' color='primary' disabled={(info?.summer?.weeklyPayouts ?? []).length === 0} style={{ marginLeft: '4px' }}>
                                    Visualizza impiego estivo
                                </Button>
                            </div>
                        </CardContent>
                    </Card>

                    <Card style={{ margin: '8px 8px 16px' }}>
                        <CardContent>
                            <Typography variant='h1' style={{ fontSize: '1.8em', textAlign: 'center', margin: '-16px -16px 8px -16px', backgroundColor: '#edf7ed', padding: '12px 8px' }}>
                                Compensi altre ASD
                            </Typography>

                            <Table size='small'>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                        <TableCell>data</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>importo</TableCell>
                                        <TableCell>nome ASD</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {(info?.otherAsdPayouts ?? []).map((payout: any) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{moment(payout.date).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>€ {parseFloat(payout.amount).toFixed(2)}</TableCell>
                                                <TableCell>{payout.asdName}</TableCell>
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} >
                                                    <Button onClick={() => deleteOtherAsdPayout(payout.id)} variant='outlined' size='small' color='secondary' disabled={isDeletingOtherAsdPayout} >
                                                        Elimina
                                                    </Button>
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>

                            <div style={{ textAlign: 'center', marginTop: '20px' }} >
                                <Button onClick={() => setIsAddOtherAsdPayoutDialogOpen(true)} variant='outlined' color='primary' disabled={isDeletingOtherAsdPayout}>
                                    Aggiungi compenso
                                </Button>
                            </div>
                        </CardContent>
                    </Card>

                    {(((info?.summer?.totals ?? null) !== null && (info?.winter?.totals ?? null) !== null) &&
                        ((Object.values((info?.winter?.totals ?? {})[parseInt(year)] ?? {})).some(((x: any) => (x >= 0.001))) ||
                            (Object.values((info?.summer?.totals ?? {})[parseInt(year)] ?? {})).some(((x: any) => (x >= 0.001))) ||
                            (Object.values((info?.winter?.totals ?? {})[parseInt(year) + 1] ?? {})).some(((x: any) => (x >= 0.001))) ||
                            (Object.values((info?.summer?.totals ?? {})[parseInt(year) + 1] ?? {})).some(((x: any) => (x >= 0.001))))) ? (
                        <>
                            {[0, 1, 2].map((boxIdx: number) => {
                                return (
                                    <Card style={{ margin: '8px 8px 16px' }}>
                                        <CardContent>
                                            <Typography variant='h1' style={{ fontSize: '1.8em', textAlign: 'center', margin: '-16px -16px 8px -16px', backgroundColor: '#edf7ed', padding: '12px 8px', fontWeight: 'bold' }}>
                                                TOTALI {boxIdx === 2 ? (<>{parseInt(year) % 100}-{(parseInt(year) + 1) % 100}</>) : (parseInt(year) + boxIdx)}
                                            </Typography>

                                            <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                                <Table size='small'>
                                                    <TableHead>
                                                        <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                                            <TableCell />
                                                            <TableCell style={{ textAlign: 'right' }}>pagato</TableCell>
                                                            <TableCell style={{ textAlign: 'right' }}>da pagare</TableCell>
                                                            <TableCell style={{ textAlign: 'right' }}>totale</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>
                                                            totale compensi
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                            € {((boxIdx === 2 ? (info.summer.totals[parseInt(year)].paid + info.summer.totals[parseInt(year) + 1].paid + info.winter.totals[parseInt(year)].paid + info.winter.totals[parseInt(year) + 1].paid) : (info.summer.totals[parseInt(year) + boxIdx].paid + info.winter.totals[parseInt(year) + boxIdx].paid))).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                            € {((boxIdx === 0 ? firstYearTotalAmount : (boxIdx === 1 ? secondYearTotalAmount : (firstYearTotalAmount + secondYearTotalAmount))) - (boxIdx === 2 ? (info.summer.totals[parseInt(year)].paid + info.summer.totals[parseInt(year) + 1].paid + info.winter.totals[parseInt(year)].paid + info.winter.totals[parseInt(year) + 1].paid) : (info.summer.totals[parseInt(year) + boxIdx].paid + info.winter.totals[parseInt(year) + boxIdx].paid))).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                            € {((boxIdx === 0 ? firstYearTotalAmount : (boxIdx === 1 ? secondYearTotalAmount : (firstYearTotalAmount + secondYearTotalAmount))).toFixed(2))}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            totale netto
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {((boxIdx === 2 ? (info.summer.totals[parseInt(year)].netAmount + info.summer.totals[parseInt(year) + 1].netAmount + info.winter.totals[parseInt(year)].netAmount + info.winter.totals[parseInt(year) + 1].netAmount) : (info.summer.totals[parseInt(year) + boxIdx].netAmount + info.winter.totals[parseInt(year) + boxIdx].netAmount))).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell colSpan={2} />
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            tasse collaboratore
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {((boxIdx === 2 ? (info.summer.totals[parseInt(year)].trainerTaxes + info.summer.totals[parseInt(year) + 1].trainerTaxes + info.winter.totals[parseInt(year)].trainerTaxes + info.winter.totals[parseInt(year) + 1].trainerTaxes) : (info.summer.totals[parseInt(year) + boxIdx].trainerTaxes + info.winter.totals[parseInt(year) + boxIdx].trainerTaxes))).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell colSpan={2} />
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            tasse E.S
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {((boxIdx === 2 ? (info.summer.totals[parseInt(year)].esTaxes + info.summer.totals[parseInt(year) + 1].esTaxes + info.winter.totals[parseInt(year)].esTaxes + info.winter.totals[parseInt(year) + 1].esTaxes) : (info.summer.totals[parseInt(year) + boxIdx].esTaxes + info.winter.totals[parseInt(year) + boxIdx].esTaxes))).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell colSpan={2} />
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            totale lordo
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {((boxIdx === 2 ? ((info.summer.totals[parseInt(year)].netAmount + info.summer.totals[parseInt(year)].trainerTaxes + info.summer.totals[parseInt(year)].esTaxes + info.winter.totals[parseInt(year)].netAmount + info.winter.totals[parseInt(year)].trainerTaxes + info.winter.totals[parseInt(year)].esTaxes) + (info.summer.totals[parseInt(year) + 1].netAmount + info.summer.totals[parseInt(year) + 1].trainerTaxes + info.summer.totals[parseInt(year) + 1].esTaxes + info.winter.totals[parseInt(year) + 1].netAmount + info.winter.totals[parseInt(year) + 1].trainerTaxes + info.winter.totals[parseInt(year) + 1].esTaxes)) : (info.summer.totals[parseInt(year) + boxIdx].netAmount + info.summer.totals[parseInt(year) + boxIdx].trainerTaxes + info.summer.totals[parseInt(year) + boxIdx].esTaxes + info.winter.totals[parseInt(year) + boxIdx].netAmount + info.winter.totals[parseInt(year) + boxIdx].trainerTaxes + info.winter.totals[parseInt(year) + boxIdx].esTaxes))).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell colSpan={2} />
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </>
                    ) : (
                        <>
                            <Card style={{ margin: '8px 8px 16px' }}>
                                <CardContent>
                                    <Typography variant='h1' style={{ fontSize: '1.8em', textAlign: 'center', margin: '-16px -16px 8px -16px', backgroundColor: '#edf7ed', padding: '12px 8px', fontWeight: 'bold' }}>
                                        TOTALI
                                    </Typography>

                                    <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                                    <TableCell />
                                                    <TableCell style={{ textAlign: 'right' }}>pagato</TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>da pagare</TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>totale</TableCell>
                                                </TableRow>
                                            </TableHead>


                                            {((info?.summer?.totals ?? null) !== null && (info?.winter?.totals ?? null) !== null) && (
                                                <>
                                                    <TableRow>
                                                        <TableCell>
                                                            TOTALE {parseInt(year)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {(info.summer.totals[parseInt(year)].paid + info.winter.totals[parseInt(year)].paid).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {(firstYearTotalAmount - (info.summer.totals[parseInt(year)].paid + info.winter.totals[parseInt(year)].paid)).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {firstYearTotalAmount.toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            TOTALE {parseInt(year) + 1}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {(info.summer.totals[parseInt(year) + 1].paid + info.winter.totals[parseInt(year) + 1].paid).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {(secondYearTotalAmount - (info.summer.totals[parseInt(year) + 1].paid + info.winter.totals[parseInt(year) + 1].paid)).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {secondYearTotalAmount.toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                                                        <TableCell>
                                                            <b>TOTALE {parseInt(year) % 100}-{(parseInt(year) + 1) % 100}</b>
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {(info.summer.totals[parseInt(year)].paid + info.winter.totals[parseInt(year)].paid + info.summer.totals[parseInt(year) + 1].paid + info.winter.totals[parseInt(year) + 1].paid).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {((firstYearTotalAmount + secondYearTotalAmount) - (info.summer.totals[parseInt(year)].paid + info.winter.totals[parseInt(year)].paid + info.summer.totals[parseInt(year) + 1].paid + info.winter.totals[parseInt(year) + 1].paid)).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            € {(firstYearTotalAmount + secondYearTotalAmount).toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </>
                    )}

                    <div style={{ fontSize: '0.01em' }}>&nbsp;</div>
                </div>
            </div>

            <SummerWeeksDetailDialog
                open={isSummerWeekDetailDialogOpen}
                closeDialog={() => setIsSummerWeekDetailDialogOpen(false)}
                data={info?.summer?.weeklyPayouts ?? []}
            />

            <WinterScheduleDialog
                open={isWinterScheduleDialogOpen}
                closeDialog={() => setIsWinterScheduleDialogOpen(false)}
                selectedTrainerId={realTrainerId}
                overrideDialogTitle={'Impiego settimanale'}
            />

            <AvailabilityDetailDialog
                open={isAvailabilityDetailDialogOpen}
                closeDialog={() => setIsAvailabilityDetailDialogOpen(false)}
                selectedTrainerId={realTrainerId}
                overrideDialogTitle={'Impiego estivo'}
            />

            <AddOtherAsdPayoutDialog
                open={isAddOtherAsdPayoutDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsAddOtherAsdPayoutDialogOpen(false);

                    if (shouldRefresh) refreshData(true);
                }}
                selectedTrainerId={realTrainerId}
            />
        </PageContainer >
    );
};

export default TrainerPayoutsReportTable;
