import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import api from '../../../../../../common/utils/api';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import StepFooter from '../../components/StepFooter/StepFooter';
import { GenericStepProps } from '../types';
import DocumentCard from './components/DocumentCard/DocumentCard';
import MissingDocumentsAlert from './components/MissingDocumentsAlert/MissingDocumentsAlert';
import NoMissingDocumentsAlert from './components/NoMissingDocumentsAlert/NoMissingDocumentsAlert';
import { Asset, Document } from './types';
import AquiloneDetails from '../RegistrationStep6/components/AquiloneDetails/AquiloneDetails';
import snackbar from '../../../../../../common/utils/snackbar';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';

interface Props extends GenericStepProps {
    documentAssets: GenericObject;
    setDocumentAssets: Dispatch<SetStateAction<GenericObject>>;
}

const RegistrationStep4 = (props: Props) => {
    const { courseId, data, prevStep, nextStep, rawOnChange, documentAssets, setDocumentAssets, courseData, gtmStepPush, selectedLocationId, preselectedLocation } = props;
    const { participant } = data;

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isLoading, setIsLoading] = useState(true);
    const [documentsData, setDocumentsData] = useState<Document[]>([]);

    useEffect(() => {
        const campTown = (courseData?.locations?.find((l: any) => l.id === selectedLocationId) ?? preselectedLocation)?.town?.name;

        const analyticsData = {
            visitorLoggedIn: '1',
            summer_camp: courseData?.name ?? '',
            camp_town: campTown ?? '',
            student_country: 'Italy',
            student_city: data?.participant?.address?.town_name ?? '',
            student_zip: data?.participant?.address?.zip ?? '',
        };

        gtmStepPush(3, analyticsData);
    }, [gtmStepPush, courseData, selectedLocationId, data, preselectedLocation]);

    const missingDocuments = documentsData.filter(d => d.status === 'missing');
    const countMissingDocuments = missingDocuments.length;

    const handleAssetChange = useCallback((documentId: string, assets: Asset[]) => {
        setDocumentAssets(currentState => {
            return {
                ...currentState,
                [documentId]: assets
            };
        });
    }, [setDocumentAssets]);

    useEffect(() => {
        api.request('/courses/' + courseId + '/documents/list', 'POST', { participant }).then(res => {
            setDocumentsData(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [courseId, participant]);

    const internalNextStep = useCallback(() => {
        const nextStepImpl = () => {
            const documents = Object.entries(documentAssets).map(([key, value]) => {
                return {
                    documentTypeId: key,
                    assets: value.map((asset: Asset) => asset.id)
                };
            }).filter(document => document.assets.length > 0);

            rawOnChange('documents', documents);
            nextStep();
        }

        if (courseId === '981b6667-d548-434e-b7ac-676eac174324') {
            const numeroCartaIdentita = data?._additionals?.numeroCartaIdentita;

            if (!/[A-Z]{2}[0-9]{5}[A-Z]{2}/.test(numeroCartaIdentita) && !/[A-Z]{2}[0-9]{7}/.test(numeroCartaIdentita)) {
                snackbar.error('Il numero della carta d\'identità non è corretto');
                return;
            }

            if (!data?._additionals?.townName) {
                snackbar.error('Comune di rilascio non valido');
                return;
            }

            if (!data?._additionals?.documentExpireDate) {
                snackbar.error('Data di scadenza non valida');
                return;
            }

            showMessageDialog({
                title: 'Conferma dati',
                message: (
                    <>
                        <p style={{ margin: '0px' }}>
                            Dichiaro che i dati inseriti corrispondono a quelli del minore di cui sono legale rappresentante e per il quale richiedo l’iscrizione al camp.
                        </p>
                    </>
                ),
                actions: [
                    {
                        text: 'Annulla',
                        action: () => {
                            closeMessageDialog();
                        }
                    },
                    {
                        text: 'Conferma',
                        action: () => {
                            nextStepImpl();
                            closeMessageDialog();
                        }
                    }
                ]
            });
        } else {
            nextStepImpl();
        }
    }, [documentAssets, nextStep, rawOnChange, courseId, data, showMessageDialog, closeMessageDialog]);

    const isUploading = Object.entries(documentAssets).some((d: any) => d[1].some((a: any) => a.status === 'uploading'));

    return (
        <>
            {courseId === '981b6667-d548-434e-b7ac-676eac174324' ? (
                <>
                    <AquiloneDetails data={data} rawOnChange={rawOnChange} />
                </>
            ) : (
                <>
                    {documentsData.length > 0 ? (
                        <>
                            {countMissingDocuments === 0 ? (
                                <NoMissingDocumentsAlert count={documentsData.length} seasonId={courseData.season.id} />
                            ) : (
                                <MissingDocumentsAlert missingDocuments={missingDocuments} count={documentsData.length} courseData={courseData} />
                            )}

                            <div>
                                {documentsData.map((document: Document) => {
                                    return <DocumentCard initialAssets={documentAssets[document.documentType.id] ?? []} document={document} handleAssetChange={handleAssetChange} />;
                                })}
                            </div>
                        </>
                    ) : (
                        <NoMissingDocumentsAlert count={documentsData.length} seasonId={courseData.season.id} />
                    )}
                </>
            )}

            <StepFooter isLoading={isLoading || isUploading} prevStep={prevStep} nextStep={internalNextStep} />
        </>
    );
};

export default RegistrationStep4;
