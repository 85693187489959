import { useMemo } from 'react';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import BoringAvatar from 'boring-avatars';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';

import { PersonForRegistration } from '../../../../types';

interface Props {
    person: PersonForRegistration;
    onClick: () => void;
}

const PersonListItem = (props: Props) => {
    const { person, onClick } = props;
    const { name, surname, taxCode, alreadyRegistered, validAge } = person;

    const taxCodeHash = Base64.stringify(sha256(taxCode));

    const additionalMessage = useMemo(() => {
        if (!validAge) {
            return 'Non rispetta i requisiti d\'età';
        } else if (alreadyRegistered) {
            return 'Già iscritto al corso';
        }
    }, [alreadyRegistered, validAge]);

    return ( 
        <ListItem button disabled={!validAge || alreadyRegistered} onClick={onClick}>
            <ListItemAvatar>
                <Avatar>
                <BoringAvatar
                    name={taxCodeHash}
                    variant='beam'
                    colors={['#FFAD08', '#EDD75A', '#73B06F', '#0C8F8F', '#405059']}
                    />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name + ' ' + surname} secondary={
                <>
                    <span style={{display: 'block'}}>{taxCode}</span>

                    {additionalMessage && (
                        <span style={{display: 'block', marginTop: '2px'}}>
                            {additionalMessage}
                        </span>
                    )}
                </>
            } />
        </ListItem>
    );
};

export default PersonListItem;
