import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../../../common/components/CustomTextField/styled';
import api from '../../../../../../../../common/utils/api';
import snackbar from '../../../../../../../../common/utils/snackbar';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import { Divider, Typography } from '@material-ui/core';
import TownAutoComplete from '../../../../../../../public/pages/registrationPage/components/GenericPersonForm/sections/address/components/TownAutocomplete/TownAutocomplete';
import SpecialDietsV2Selector from '../../../../../../../public/pages/registrationPage/steps/RegistrationStep6/components/SpecialDietsV2Selector/SpecialDietsV2Selector';
import useValidatePhoneNumber from '../../../../../../../public/pages/registrationPage/components/GenericPersonForm/hooks/useValidatePhoneNumber';
import IBAN from 'iban';
import ZipAutocomplete from '../../../../../../../public/pages/registrationPage/components/GenericPersonForm/sections/address/components/ZipAutocomplete/ZipAutocomplete';

interface Props {
    open: boolean;
    closeDialog: () => void;
}

const SuperUserDetailsDialog = (props: Props) => {
    const { open, closeDialog } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [townLegalId, setTownLegalId] = useState<string | null>(null);
    const [zipCode, setZipCode] = useState<string | null>(null);
    const [specialDiets, setSpecialDiets] = useState<GenericObject[]>([]);

    const [townZips, setTownZips] = useState([]);

    useEffect(() => {
        setTownZips([]);

        if (townLegalId) {
            api.request('/towns/' + townLegalId).then(res => {
                setTownZips(res?.zips ?? []);
            });
        }
    }, [townLegalId]);

    const dummyTownData = useMemo(() => {
        return {
            dummy: {
                address: {
                    town_legal_id: townLegalId,
                    zip: zipCode
                }
            }
        };
    }, [townLegalId, zipCode]);

    const dummyTownRawOnChange = useCallback((path: string, value: string) => {
        if (path === 'dummy.address.town_legal_id') {
            setTownLegalId(value);
        }

        if (path === 'dummy.address.zip') {
            setZipCode(value);
        }
    }, []);

    const [data, setData] = useState<GenericObject>({});

    const { phone: isPhoneValid } = useValidatePhoneNumber({ formPrefix: 'dummy', data: { dummy: { phone: data?.phone ?? '' } } });

    useEffect(() => {
        if (!open) return;

        setIsLoading(true);

        api.request('/admin/super_users/me').then(res => {
            setData(res);
            setTownLegalId(res.residenceLegalId);
            setZipCode(res.zipCode);
            setSpecialDiets(res.specialDiets);
            setIsLoading(false);
        })
    }, [open]);

    useEffect(() => {
        if (!open) return;

        setTownLegalId(null);
        setZipCode(null);
        setSpecialDiets([]);
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/super_users/me', 'POST', { townLegalId, zipCode, specialDiets, phone: data?.phone, iban: data?.iban }).then(res => {
            snackbar.success('Dati salvati con successo!');
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [townLegalId, zipCode, specialDiets, closeDialog, data]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Dettagli anagrafici'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <StyledTextField
                    label={'Nome'}
                    variant='outlined'
                    value={data?.firstName}
                    style={{ marginRight: '6px' }}
                    fullWidth
                    disabled={isLoading}
                    key={data?.firstName}
                    inputProps={{ readOnly: true }}
                />

                <StyledTextField
                    label={'Cognome'}
                    variant='outlined'
                    value={data?.lastName}
                    style={{ marginLeft: '6px' }}
                    fullWidth
                    disabled={isLoading}
                    key={data?.lastName}
                    inputProps={{ readOnly: true }}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <StyledTextField
                    label={'Codice fiscale'}
                    variant='outlined'
                    value={data?.taxCode}
                    fullWidth
                    disabled={isLoading}
                    key={data?.taxCode}
                    inputProps={{ readOnly: true }}
                    style={{ marginBottom: '4px', marginRight: '6px' }}
                />

                <StyledTextField
                    label={'Indirizzo email'}
                    variant='outlined'
                    value={data?.email}
                    fullWidth
                    disabled={isLoading}
                    key={data?.email}
                    inputProps={{ readOnly: true }}
                    style={{ marginBottom: '4px', marginLeft: '6px' }}
                />
            </div>

            <Typography variant="caption" display="block" gutterBottom style={{ marginTop: '4px' }}>
                Contatta un amministratore per modificare nome, cognome, codice fiscale o indirizzo email.
            </Typography>

            <Divider style={{ marginTop: '12px', marginBottom: '24px' }} />

            <div style={{ display: 'flex', marginBottom: '8px' }}>
                <StyledTextField
                    label={'Cellulare'}
                    variant='outlined'
                    value={data?.phone}
                    fullWidth
                    disabled={isLoading}
                    error={!isPhoneValid && data?.phone}
                    style={{ marginBottom: '4px', marginRight: '4px', flex: 1 }}
                    onChange={(e: any) => {
                        setData(d => ({ ...d, phone: e.target.value }));
                    }}
                    key={isLoading ? 'a0' : 'b0'}
                />

                <StyledTextField
                    label={'IBAN'}
                    variant='outlined'
                    value={data?.iban}
                    fullWidth
                    disabled={isLoading}
                    error={!IBAN.isValid(data?.iban ?? '') && data?.iban}
                    style={{ marginBottom: '4px', marginLeft: '4px', flex: 2 }}
                    onChange={(e: any) => {
                        setData(d => ({ ...d, iban: e.target.value }));
                    }}
                    key={isLoading ? 'a1' : 'b1'}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <TownAutoComplete data={dummyTownData} formPrefix={'dummy'} rawOnChange={dummyTownRawOnChange} style={{ marginRight: '3px', flex: 2 }} />

                <ZipAutocomplete
                    data={dummyTownData}
                    formPrefix={'dummy'}
                    isLoading={townZips.length === 0 && !!townLegalId}
                    rawOnChange={dummyTownRawOnChange}
                    zips={townZips}
                    townLegalId={townLegalId ?? ''}
                    style={{ marginLeft: '3px', flex: 1 }}
                />
            </div>

            <SpecialDietsV2Selector
                selectedSpecialDiets={specialDiets}
                rawOnChange={(field, value) => {
                    setSpecialDiets(value)
                }}
                disabled={false}
            />
        </CustomDialogWrapper>
    );
};

export default SuperUserDetailsDialog;
